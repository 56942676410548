import { DateUtil } from '@/utils/dateutil';
import numeral from 'numeral';


function formatMoneyValue(currency, number) {
    if (typeof number === 'number') {
        number = parseFloat(number);

        if (number >= 0) {
            return currency + ' ' + numeral(number).format('0,0.00') + ' ';
        } else {
            return '(' + currency + ' ' + numeral((-1 * number)).format('0,0.00') + ')';
        }
    }
    return currency + " 0.00";
}

function formatNumber(number) {
    if (typeof number === 'number') {
        number = parseInt(number);

        if (number >= 0) {
            return number.toLocaleString();
        } else {
            return '(' + (-1 * number).toLocaleString() + ')';
        }
    }
    return "0";
}

function getDefaultBillingReportObj() {
    return {
        billingReportNo: '',
        billingNumber: '',
        assetOwnerId: '',
        assetOwner: '',
        clientId: '',
        client: '',
        clientAccountId: '',
        clientAccountNo: '',
        status: 'Pending',

        chargeType: null,
        chargeTypeDetails: {},

        startDate: 0,
        endDate: 0,
        dueDate: 0,
        transactions: [],
        delayedTransfers: [],
        adjustments: [],

        totalChargeTypeAmount: 0,
        totalAdjustmentAmount: 0,
        totalVATExclusiveAmount: 0,
        total12PercentVATAmount: 0,
        totalVATInclusiveAmount: 0,
        remarks: ''
    }
}

function cleanupFields(billingReport) {
    let cleanedObj = { ...billingReport };

    // Remove unnecessary fields during saving
    delete cleanedObj['Start Date'];
    delete cleanedObj['End Date'];
    delete cleanedObj['Due Date'];

    delete cleanedObj['Date Created'];
    delete cleanedObj['Date Updated'];
    delete cleanedObj['Date Approved'];
    delete cleanedObj['Date Rejected'];
    delete cleanedObj['Date Cancelled'];

    delete cleanedObj['Created By'];
    delete cleanedObj['Updated By'];
    delete cleanedObj['Approved By'];
    delete cleanedObj['Rejected By'];
    delete cleanedObj['Cancelled By'];

    delete cleanedObj['Billing Number'];
    delete cleanedObj['Charge Details'];

    delete cleanedObj['_showDetails'];

    return cleanedObj;
}

function getChargeTypeDetails(billingReport) {
    let chargeDetails = '';

    let chargeType = billingReport.chargeType ? billingReport.chargeType : '-';
    let chargeTypeDetails = billingReport.chargeTypeDetails ? billingReport.chargeTypeDetails : '-';

    if (chargeType === 'Rental') {
        let assetType = chargeTypeDetails.assetType ? chargeTypeDetails.assetType : '';
        let currency = chargeTypeDetails.currency ? chargeTypeDetails.currency : '';
        let rentRate = chargeTypeDetails.rentRate ? chargeTypeDetails.rentRate : '';
        chargeDetails = assetType + " w/ " + formatMoneyValue(currency, rentRate) + ' rent rate (VAT Exclusive)';

    } else if (chargeType === 'Trucking') {
        let description = chargeTypeDetails.description ? chargeTypeDetails.description : '';
        chargeDetails = description;

    } else {
        let currency = chargeTypeDetails.currency ? chargeTypeDetails.currency : '';
        let vatExclusiveRate = chargeTypeDetails.vatExclusiveRate ? chargeTypeDetails.vatExclusiveRate : '-';
        chargeDetails = currency + ' ' + formatMoneyValue(currency, vatExclusiveRate) + ' (VAT Exclusive)';
    }

    return chargeDetails;
}

function getCalendarDays(startDate, endDate) {
    // +1 for including the startDate
    return DateUtil.getNoOfDays(startDate, endDate) + 1;
}

function getTotalDays(calendarDays, quantity) {
    return calendarDays * quantity;
}

function getCalendarHours(startDate, endDate) {
    let noOfDays = DateUtil.getNoOfDays(startDate, endDate);
    return noOfDays * 8;
}

function getTotalHours(calendarHours, quantity) {
    return calendarHours * quantity;
}

export const BillingReportUtil = {
    formatMoneyValue,
    formatNumber,
    getDefaultBillingReportObj,
    cleanupFields,
    getChargeTypeDetails,
    getCalendarDays,
    getTotalDays,
    getCalendarHours,
    getTotalHours
}
